<template>
	<div class="personall-wrap">
		<div class="title-wrap flex-row">
			<p class="ft-14">编辑个人信息</p>
		</div>
		<div class="handle-list">
			<ul class="list">
				<li class="li flex-row ft-14">
					<div class="left flex-row">
						<div class="label">密码：</div>
						<div class="value">******</div>
					</div>
					<div class="action-wrap flex-row">
						<div class="action" @click="updatePwd">修改密码</div>
					</div>
				</li>
			</ul>
		</div>
		<reset-password ref="resetPassword"></reset-password>
	</div>
</template>

<script>
	import resetPassword from '@/components/user/resetPassword.vue'
	export default{
		components: {
			resetPassword
		},
		data() {
			return {
			}
		},
		methods: {
			updatePwd() {
				this.$refs.resetPassword.title = '修改密码'
				this.$refs.resetPassword.show()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.personall-wrap{
		.title-wrap{
			padding: 0 3vw;
			width: 100%;
			height: 6vh;
			line-height: 6vh;
			justify-content: flex-start;
			position: relative;
			&::after{
				content: '';
				width: 100%;
				height: 1px;
				background-color: #3A3A41;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			p{
				position: relative;
				color: #FFFFFF;
				font-weight: bold;
				// &::after{
				// 	content: '';
				// 	position: absolute;
				// 	left: 50%;
				// 	bottom: 6px;
				// 	width: 10px;
				// 	height: 2px;
				// 	transform: translateX(-50%);
				// 	background: linear-gradient(270deg, #67A0FF 0%, #3C78DD 100%);
				// 	border-radius: 2px;					
				// }
			}
		}
		.handle-list{
			.list{
				padding: 0 3vw;
				.li{
					color: #FFFFFF;
					justify-content: space-between;
					height: 16vh;
					position: relative;
					&::after{
						content: '';
						width: 100%;
						height: 1px;
						background-color: #3A3A41;
						position: absolute;
						left: 0;
						bottom: 0;
					}
					.left{
						width: 80%;
						justify-content: flex-start;
						.label{
							width: 30%;
						}
						.value{
							width: 40%;
						}
					}
					.action-wrap {
						width: 20%;
						justify-content: flex-end;
						.action{
							padding: 0 4px;
							color: #3C78DD;
							cursor: pointer;
						}
					};
				}
			}
		}
	}
</style>
