<template>
	<div class="container">
		<header-view ref="header" :class="{'on': minBar}" :showMenu="showMenu" :page="99" @change="changeMenu"></header-view>
		<div class="personal-wrap flex-row">
			<div class="left flex-col">
				<div class="user">
					<div class="user-icon"></div>
				</div>
				<div class="menu-warp ft-14 t-center">
					<div class="item" :class="{'active': menuType === '1'}" @click="menuTap('1')">
						<span>预约项目</span>
					</div>
					<div class="item" :class="{'active': menuType === '2'}" @click="menuTap('2')">
						<span>修改密码</span>
					</div>
				</div>
			</div>
			<div class="right">
				<project-list v-if="menuType === '1'"></project-list>
				<personall-info v-if="menuType === '2'"></personall-info>
			</div>
		</div>
	</div>
</template>

<script>
	import headerView from '../../components/header/headerView.vue'
	import projectList from './components/projectList.vue'
	import personallInfo from './components/personalInfo.vue'
	export default{
		components: {
			headerView,
			projectList,
			personallInfo
		},
		data() {
			return {
				minBar: false,
				showMenu: false,
				menuType: '1'
			}
		},
		created() {
			if(this.$route.query.type) {
				this.menuType = this.$route.query.type
			}
		},
		methods: {
			menuTap(val) {
				this.menuType = val
			},
			changeMenu(bool) {
				this.showMenu = !bool
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container{
		.personal-wrap{
			padding-top: 15vh;
			width: 90vw;
			min-height: 100vh;
			margin: 0 auto;
			justify-content: space-between;
			align-items: flex-start;
			.left{
				width: 15%;
				min-height: 50vh;
				padding: 4vh 0;
				justify-content: flex-start;
				background: linear-gradient(180deg, #393943 0%, #2A2A32 100%);
				border-radius: 10px;
				.user{
					// background-color: RGBA(86, 84, 91, 1);
					border-radius: 50%;
					.user-icon{
						width: 4.5vw;
						height: 4.5vw;
						background-image: url(../../assets/images/user.png);
						background-size: 100% 100%;
						background-position: center;
						background-repeat: no-repeat;
						cursor: pointer;
						transition: all .3s linear;
					}
				}
				.menu-warp{
					position: relative;
					width: 80%;
					margin: 3vh auto 0;
					color: #BABABA;
					&::before{
						position: absolute;
						left: 0;
						top: 0;
						content: '';
						width: 100%;
						height: 1px;
						background-color: #3A3A41;
						// transform: scaleY(0.5);
					}
					.item{
						line-height: 24px;
						margin: 3vh 0;
						cursor: pointer;
						&.active{
							color: #3C78DD;
						}
					}
				}
			}
			.right{
				width: calc(100% - 16%);
				min-height: 50vh;
				background: linear-gradient(180deg, #393943 0%, #2A2A32 100%);
				border-radius: 10px;
			}
		}
	}
</style>
