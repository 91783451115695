<template>
	<div class="project-list">
		<div class="title-wrap flex-row">
			<p class="ft-14">我预约的项目</p>
		</div>
		<div class="total-wrap flex-row ft-14">
			<div class="total-icon">
				<div class="icon"></div>
			</div>
			<div class="label">预约项目总数（个）</div>
			<div class="total-num">数量：{{totalNum}}</div>
		</div>
		<div class="table-wrap">
			<el-table
				v-loading="loading"
				element-loading-text="拼命加载中"
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)"
				:data="tableData"
				:cell-style="{background:'transparent'}"
				style="width: 100%">
				<el-table-column
				prop="name"
				label="项目名称">
				</el-table-column>
				<el-table-column
				prop="createTime"
				label="预约时间">
				</el-table-column>
				<el-table-column
				prop="status_dictText"
				label="预约状态">
				</el-table-column>
				<div slot="empty" v-if="!loading" class="empty-wrap">
					<img src="@/assets/images/empty_tabel.png" />
					<p>抱歉，没有相关数据</p>
				</div>
			</el-table>
			<el-pagination
				layout="prev, pager, next"
				background
				hide-on-single-page
				:current-page="pageNo"
				:page-size="pageSize"
				@prev-click="prev"
				@next-click="next"
				@current-change="topage"
				:total="totalNum">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import { shareList } from '@/api/api.js'
	export default{
		data() {
			return {
				pageNo: 1,
				pageSize: 5,
				totalNum: 0,
				loading: true,
				tableData: []
			}
		},
		created() {
			this.getShareList()
		},
		methods: {
			prev(){
				this.loading = true
				this.pageNo--
				this.getShareList()
			},
			next(){
				this.loading = true
				this.pageNo++
				this.getShareList()
			},
			topage(e) {
				this.loading = true
				this.pageNo = e
				this.getShareList()
			},
			getShareList() {
				shareList({pageNo:this.pageNo,pageSize:this.pageSize}).then(res =>{
					if (res.success) {
						setTimeout(() =>{
							this.loading = false
							this.totalNum = res.result.total
							this.tableData = res.result.records
						},500)
					}
				})
			},
			currentChange(currentPage){
				this.pageNo = currentPage;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.project-list{
		width: 100%;
		.title-wrap{
			padding: 0 3vw;
			width: 100%;
			height: 6vh;
			line-height: 6vh;
			justify-content: flex-start;
			position: relative;
			&::after{
				content: '';
				width: 100%;
				height: 1px;
				background-color: #3A3A41;
				position: absolute;
				left: 0;
				bottom: 0;
			}
			p{
				position: relative;
				color: #3C78DD;
				font-weight: bold;
				&::after{
					content: '';
					position: absolute;
					left: 50%;
					bottom: 6px;
					width: 10px;
					height: 2px;
					transform: translateX(-50%);
					background: linear-gradient(270deg, #67A0FF 0%, #3C78DD 100%);
					border-radius: 2px;					
				}
			}
		}
		.empty-wrap{
			padding: 2vh 0;
		}
		.total-wrap{
			height: 20vh;
			justify-content: flex-start;
			padding: 0 3vw;
			color: #BABABA;
			.total-icon{
				background-color: #3F3F48;
				border-radius: 50%;
				.icon{
					width: 4.5vw;
					height: 4.5vw;
					background-image: url(../../../assets/images/total-icon.png);
					background-size: 24px 26px;
					background-position: center;
					background-repeat: no-repeat;
					transition: all .3s linear;
				}
			}
			.label{
				margin-left: 1vw;
			}
			.total-num{
				margin-left: 3vw;
			}
		}
		.table-wrap{
			position: relative;
			padding: 0 1vw 2vh;
			&::after{
				content: '';
				width: 100%;
				height: 1px;
				background-color: #3A3A41;
				position: absolute;
				left: 0;
				top: 0;
			}
			/deep/ .el-table{
				color: #8F8F8F;
				&::before{
					height: 0;
				}
				background-color: transparent!important;
				tr{
					background-color: transparent!important;
				}
				th{
					background-color: transparent!important;
				}
				td,th.is-leaf{
					border-bottom: none;
				}
			}
			/deep/ .el-pagination{
				margin-top: 2vh;
			}
			/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
				background-color: #3C78DD;
			}
		}
	}
</style>
